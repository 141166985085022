import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination, SEO } from '../components/common'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    const posts = data.posts.edges

    return (
        <React.Fragment>
            {/*<MetaData location={location} />*/}
            <SEO title={'Daily Vis'} description={'A quantified self data blog'} />
            <Layout isHome={true}>
                <div className="container">
                    <h2>Quantified Self Writings</h2>
                    <section className="post-feed">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </React.Fragment>
    )
}

Index.propTypes = {
    // data: PropTypes.shape({
    //     allGhostPost: PropTypes.object.isRequired,
    // }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery{
    posts: allMdx(
        sort: {order: DESC, fields: [frontmatter___date]},
        limit: 6, 
        filter: {frontmatter: {published: {eq: "true"}}}
    ) {
        edges {
          node {
            id
            timeToRead
            excerpt
            frontmatter {
              slug
              title
              date(formatString: "MMMM DD, YYYY")
              imageUrl
              description
            }
          }
        }
    }
  }
`
